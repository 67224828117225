<template>
  <div>
    <edit-space-capacity
      v-if="showEditSpaceCapacity"
      :showModal="showEditSpaceCapacity"
      :meetingtypeId="meetingtypeId"
      :onClickCancel="hideModal"
    />

    <edit-space-meetingtype
      v-if="showEditSpaceMeetingtype"
      :showModal="showEditSpaceMeetingtype"
      :meetingtype="meetingtype"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditSpaceMeetingtype"
    >
      <template v-slot:title>Settings</template>
    </ui-section-header>

    <table class="table is-fullwidth is-striped is-narrow">
      <tbody>
        <tr>
          <td>Activated</td>
          <td>
            <font-awesome-icon
              v-if="meetingtype.IsActive"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!meetingtype.IsActive"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
        </tr>
        <tr>
          <td>
            Export group
          </td>
          <td>{{ getExportgroupName(meetingtype.ExportgroupId) }}</td>
        </tr>
        <tr>
          <td>
            Minimum duration
          </td>
          <td>{{ meetingtype.MinimumDuration }} hours</td>
        </tr>
        <tr>
          <td>Hours before start</td>
          <td>{{ meetingtype.NrOfHoursBeforeStart }} hours</td>
        </tr>
        <tr
          v-if="meetingtype.MeetingtypeId > 1 && meetingtype.UseCapacityControl"
        >
          <td>
            Capacity control
          </td>
          <td>
            <font-awesome-icon
              v-if="meetingtype.UseCapacityControl"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!meetingtype.UseCapacityControl"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
        </tr>
        <tr
          v-if="meetingtype.MeetingtypeId > 1 && meetingtype.UseCapacityControl"
        >
          <td>
            Default capacity
          </td>
          <td>
            <span>{{ meetingtype.Capacity }} seats</span>
            <span v-if="meetingtype.MeetingtypeId > 1" class="has-margin-left">
              <a @click="setShowEditSpaceCapacity" class="has-icon is-size-7">
                <span class="icon is-small">
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </span>
                <span>Set exceptions</span>
              </a>
            </span>
          </td>
        </tr>
        <tr v-if="meetingtype.MeetingtypeId === 3">
          <td>Is shareable</td>
          <td>
            <font-awesome-icon
              v-if="meetingtype.IsShareable"
              :icon="['fas', 'check']"
              class="has-text-success"
            />
            <font-awesome-icon
              v-if="!meetingtype.IsShareable"
              :icon="['fas', 'times']"
              class="has-text-danger"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locationProvider from '@/providers/location'

const ModalEditSpaceCapacity = () =>
  import('@/components/Spaces/ModalEditSpaceCapacity')
const ModalEditSpaceMeetingtype = () =>
  import('@/components/Spaces/ModalEditSpaceMeetingtype')

export default {
  components: {
    'edit-space-capacity': ModalEditSpaceCapacity,
    'edit-space-meetingtype': ModalEditSpaceMeetingtype,
  },

  props: {
    meetingtypeId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      exportgroups: [],
      locationId: Number(this.$route.params.locationId),
      showEditSpaceCapacity: false,
      showEditSpaceMeetingtype: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),

    meetingtype() {
      let meetingtype = null
      if (this.space) {
        let meetingtypeIndex = this.space.Meetingtypes.findIndex(
          (m) => m.MeetingtypeId === this.meetingtypeId
        )

        if (meetingtypeIndex > -1) {
          meetingtype = this.space.Meetingtypes[meetingtypeIndex]
        }
      }
      return meetingtype
    },
  },

  created() {
    this.getLocationExportgroups()
  },

  methods: {
    getLocationExportgroups() {
      locationProvider.methods
        .getLocationExportgroups(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.exportgroups = response.data
          }
        })
    },

    getExportgroupName(exportgroupId) {
      let name = ''
      let exportgroupIndex = this.exportgroups.findIndex(
        (e) => e.Id === exportgroupId
      )

      if (exportgroupIndex > -1) {
        name = this.exportgroups[exportgroupIndex].Name
      }
      return name
    },

    setShowEditSpaceCapacity() {
      this.showEditSpaceCapacity = true
    },

    setShowEditSpaceMeetingtype() {
      this.showEditSpaceMeetingtype = true
    },

    hideModal() {
      this.showEditSpaceCapacity = false
      this.showEditSpaceMeetingtype = false
    },
  },
}
</script>
